import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from '../PageHome';
import Biography from '../PageBiography';
import Portfolio from '../PagePortfolio';
import Services from '../PageServices';
import Contact from '../PageContact';
import Page404 from '../Page404';

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/biography" component={Biography} />
          <Route path="/portfolio" exact component={Portfolio} />
          {/* <Route path="/portfolio/:portfolioSlug" component={Portfolio} /> */}
          <Route path="/services" component={Services} />
          <Route path="/contact" component={Contact} />
          {/* <Route path="/search/:searchQuery" component={PageSearch} /> */}
          <Route component={Page404} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
