import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 0 90px;
  height: 100%;
  margin: 0 auto;
  max-width: 800px;
  @media only screen and (max-width : 690px) {
    max-width: 500px;
    padding: 0 20px;
  }
  @media only screen and (min-width: 1000px) {
    max-width: 1100px;
  }
  @media only screen and (min-width: 1300px) {
    max-width: 1425px;
  }
`;

const StyledIframe = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--very-light-grey);
  }
  .iframe-bottom{
    margin-top: 75%;
    margin-top: ${props => {
      const { styleHeight, styleWidth } = props;
      const ratio = Number(styleHeight) / Number(styleWidth);
      return `${ratio * 100}%`;
    }};
  }
`;
function IFrame(props) {
  return (
    <StyledIframe styleHeight={props.height} styleWidth={props.width}>
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe {...props} />
      <div className="iframe-bottom" />
    </StyledIframe>
  );
}

export {
  Container,
  IFrame,
};