import React, { useEffect } from 'react';
import NavHeader from '../NavHeader';
import Footer from '../Footer';
import { Container } from '../GlobalStyles';
import { Page404Wrapper } from './Page404Styles';

function Page404({ history }) {
  useEffect(() => {
    document.title = '404 | Weese Sports Portfolio';
  });

  return (
    <div>
      <NavHeader history={history} />
      <Container>
        <Page404Wrapper>
          <div>
            <h1>404</h1>
            <h2>Page not found</h2>
          </div>
        </Page404Wrapper>
      </Container>
      <Footer />
    </div>
  );
}

export default Page404;