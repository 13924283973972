import styled from 'styled-components';

const Page404Wrapper = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  height: calc(100vh - 72px - 58px);
  > div {
    width: 100%;
  }
  h1 {
    color: var(--link-accent);
    font-size: 8em;
    margin: 0;
  }
  h2 {
    font-size: 3em;
  }
`;

export { Page404Wrapper };