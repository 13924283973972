import styled from 'styled-components';
import { Container } from '../GlobalStyles';

// const renderTransparent = props => props.transparent && props.tall;

const HeaderWrapper = styled.div`
  height: 128px;
  @media only screen and (max-width: 1000px) {
    height: initial;
  }
`;
const Header = styled.header`
  background: var(--off-white);
  color: var(--light-grey);
  height: 128px;
  transition: all 500ms;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  @media only screen and (max-width: 1000px) {
    height: ${props => (props.open ? `${335+58}px` : '58px')};
    position: relative;
  }
  > div:first-child {
    height: 128px;
    /* height: 100%; */
    @media only screen and (max-width: 1000px) {
      height: 58px;
    }
  }
  .logo-container {
    display: inline-flex;
    height: 72px;
    /* width: 15%; */
    /* min-width: 280px; */
    transition: all 500ms;
    opacity: ${props => props.searchOpen ? '0' : '1'};

    h1 {
      font-family: 'Poiret One',cursive;
      font-size: 1.8em;
      line-height: 1em;
      transition: all 500ms;
      > span {
        color: var(--dark-grey);
        display: block;
        text-align: center;
        font-size: .8em;
        transition: all 500ms;
        @media only screen and (max-width: 1000px) {
          display: initial;
          margin-left: 10px;
        }
        @media only screen and (max-width: 380px) {
          display: none;
        }
      }
    }
  }
  /* @media only screen and (max-width: 1000px) {
    .logo-container {
      min-width: 250px;
    }
  } */
`;
const NavContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 1000px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;
const HeaderLogo = styled.img`
  height: calc(100% - 4px);
  max-width: 100%;
  padding: 2px 2px 2px 0;
  transition: box-shadow 2s;
  :hover {
    box-shadow: 0px 1px 19px var(--link-accent) inset;
  }
`;
const LinksContainer = styled.div`
  display: inline-flex;
  height: 58px;
  transition: height 500ms;
  @media only screen and (max-width: 1000px) {
    display: none;
  }

  ul {
    list-style: none;
    margin: 0;
    height: 100%;
    padding-left: 0;
  }
  li {
    display: inline-block;
    height: 100%;
    font-size: .75em;

    :last-child span{
      padding-right: 0;
    }
    &.spacer {
      border-right: solid 1px;
      margin: 15px 10px;
      height: calc(100% - 30px);
      border-color: var(--light-grey);
    }
  }
  a {
    height: 100%;
    display: table;
    padding: 0 10px;
    text-decoration: none;
    cursor: pointer;
    transition: color 500ms;
    color: var(--medium-dark-grey);
    :hover, :active, :focus{
      color: var(--link-accent);
      text-decoration: none;
    }
    &.active{
      color: var(--link-accent);
    }
  }
  span {
    vertical-align: middle;
    display: table-cell;
    &.search-link-span {
      padding: 0;
      font-size: 1.2em;
    }
  }
  .search-button-wrapper {
    height: 100%;
    display: table;
    padding: 0 10px;
    color: var(--medium-dark-grey);
    /* margin-left: 20px; */
    text-decoration: none;
    cursor: pointer;
    transition: color 500ms;
    :hover, :active, :focus{
      color: var(--link-accent);
      text-decoration: none;
    }
  }
`;

const NavSearchInputContainer = styled(Container)`
  display: ${props => (props.open === 'closed' ? 'none' : 'flex')};
  opacity: ${props => (props.open === 'open' ? 1 : 0)};
  position: absolute;
  right: 0;
  left: 0;
  /* height: 58px; */
  background: var(--off-white);
  transition: opacity 300ms, height 500ms;
  input {
    width: 100%;
    background: none;
    color: var(--medium-grey);
    caret-color: var(--medium-grey);
    border: none;
    font-size: 2em;
    /* text-transform: uppercase; */
    letter-spacing: 1px;
    :focus {
      outline: none;
    }
  }
  .nav-search-clear {
    background: none;
    border: none;
    color: var(--light-grey);
    font-size: 2em;
    cursor: pointer;
    font-weight: bold;
    transition: color 500ms;
    :hover, :focus, :active {
      color: var(--link-accent);
    }
  }
`;

const MobileNavContainer = styled.div`
  height: ${props => (props.open ? '335px' : '0')};
  transition: height 500ms;
  overflow: hidden;
  background: var(--off-white);
  display: none;
  @media only screen and (max-width: 1000px) {
    display: block;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    border-bottom: 1px solid var(--light-grey);
    a {
      color: var(--dark-grey);
      text-decoration: none;
      display: block;
      padding: 16px 0;
      font-size: .75em;
      transition: color 500ms;
      :hover, :active, :focus{
        color: var(--link-accent);
        text-decoration: none;
        cursor: pointer;
      }
      &.active{
        color: var(--link-accent);
      }
    }
  }
  input {
    background: var(--off-white);
    color: var(--dark-grey);
    caret-color: var(--dark-grey);
    padding: 15px 15px 15px 40px;
    width: calc(100% - 54px);
    margin: 20px 0;
    border: 0;
    border-bottom: 1px dotted var(--light-grey);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.09) inset;
    background-image: url('/mag-glass-mobile.png');
    background-repeat: no-repeat;
    background-size: 14px 14px;
    background-position: 15px center;
  }
`;
const ToggleMobileNavButton = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--medium-dark-grey);
  font-size: 1.2em;
  @media only screen and (max-width: 1000px) {
    display: block;
  }
`;

export {
  HeaderWrapper,
  Header,
  HeaderLogo,
  NavContainer,
  LinksContainer,
  NavSearchInputContainer,
  MobileNavContainer,
  ToggleMobileNavButton,
};