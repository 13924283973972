import React, { Component } from 'react';
import NavHeader from '../NavHeader';
import PageHeader from '../PageHeader';
import Footer from '../Footer';
import { Container } from '../GlobalStyles';
import { PagePortfolioWrapper, Logos } from './PagePortfolioStyles';
import { portfolioItems } from '../../config';
import Filter from './Filter';

// const currentCategory = 'All';
const categories = portfolioItems.map(v => v.category).filter((v, i, a) => a.indexOf(v) === i);
// categories.unshift(currentCategory);
// const currentCategory = categories[0];

class PagePortfolio extends Component {
  state = {
    currentCategory: categories[0],
  }

  componentDidMount() {
    document.title = 'Portfolio | Weese Sports Portfolio';
    window.scrollTo(0, 0);
  }

  setCategory = (newCategory) => {
    this.setState({ currentCategory: newCategory });
  }

  render() {
    const { history } = this.props;
    const { currentCategory } = this.state;
    return (
      <div>
        <NavHeader history={history} />
        <PageHeader path={[{title: 'Portfolio', url: '/portfolio'}]} />
        <Filter
          currentCategory={currentCategory}
          categories={categories}
          onCategoryChange={this.setCategory}
        />
        <Container>
          <Logos hidden={currentCategory!=='Writing'&&currentCategory!=='All'}>
          {portfolioItems.map(item => {
              if (item.category === 'Writing') {
                return (
                  <div key={item.title} className="portfolio-item logo-wrapper">
                    <a href={item.href} target="_blank" rel="noopener noreferrer">
                      <img src={item.img} alt={item.img.title} />
                    </a>
                  </div>
                )
              }
              return null
            })}
          </Logos>
          <Logos hidden={currentCategory!=='Podcasts'&&currentCategory!=='All'}>
          {portfolioItems.map(item => {
              if (item.category === 'Podcasts') {
                return (
                  <div key={item.title} className="portfolio-item logo-wrapper">
                    <a href={item.href} target="_blank" rel="noopener noreferrer">
                      <img src={item.img} alt={item.img.title} />
                    </a>
                  </div>
                )
              }
              return null
            })}
          </Logos>
          <Logos hidden={currentCategory!=='Radio'&&currentCategory!=='All'}>
          {portfolioItems.map(item => {
              if (item.category === 'Radio') {
                return (
                  <div key={item.title} className="portfolio-item logo-wrapper">
                    <a href={item.href} target="_blank" rel="noopener noreferrer">
                      <img src={item.img} alt={item.img.title} />
                    </a>
                  </div>
                )
              }
              return null
            })}
          </Logos>
          <PagePortfolioWrapper>
            {portfolioItems.map((item, index) => {
              if (item.category === 'Writing' || item.category === 'Podcasts' || item.category === 'Radio') {
                return null
              }
              const visible = currentCategory===item.category || currentCategory==='All'
              return (
                <div key={index} className={'portfolio-item '+(visible?'visible':'hidden')}>
                  {item.content}
                </div>
              )
            })}
          </PagePortfolioWrapper>
        </Container>
        <Footer />
      </div>
    );
  }
}

export default PagePortfolio;