import styled, { css } from 'styled-components';

const PagePortfolioWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  .portfolio-item {
    width: 33%;
    display: inline-block;
    @media only screen and (max-width : 1000px) {
      width: 50%;
    }
    @media only screen and (max-width : 690px) {
      width: 100%;
    }
    transition: height 1s, width 1s;
    &.hidden {
      height:0;
      width: 0;
      overflow: hidden;
      /* display: none; */
    }
    > div {
      /* padding: 15px; */
      margin: 15px;
      border: solid 1px var(--light-grey);
    }
    a {
      color: var(--dark-grey);
    }
    h3 {
      color: var(--link-accent);
      margin-top: 15px;
      margin-bottom: 0;
    }
    img {
      width: calc(100% + 30px);
      height: auto;
      margin: -15px -15px 0 -15px;
    }
    .date {
      font-size: .8em;
      color: var(--medium-grey);
      margin-bottom: 15px;
      display: block;
    }
    .read-more{
      color: var(--link-accent);
      display: block;
      margin-top: 15px;
    }
  }
`;

const FilterWrapper = styled.div`
  background: var(--light-grey);
  overflow-x: auto;
  .inner-wrapper{
    display: flex;
    justify-content: space-evenly;
    margin: 0 25px;
    min-width: 500px;
    > div {
      width: 100%;
      height: 80px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .category {
      background: url('https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/ScotiaBank_Place_Inside_empty_2006.jpg/1200px-ScotiaBank_Place_Inside_empty_2006.jpg') center center / cover;
    }
    button {
      background: rgba(255,255,255,.7);
      border: none;
      font-size: 1em;
      height: 100%;
      width: 100%;
      transition: all 500ms;
      &:hover, &.active {
        background: rgba(0,0,0,.7);
        color: var(--off-white);
        cursor: pointer;
      }
    }
  }
  @media only screen and (max-width : 690px) {
    > div {
      padding: 0;
    }
    .inner-wrapper {
      margin: 0;
    }
  }
`;

const Logos = styled.div`
  transition: height 1s;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  ${props => props.hidden && css`
    height: 0;
    overflow: hidden;
    /* display: none; */
  `}
  /* display: ${p => p.hidden?'block':'none'}; */
  > div {
    display: table;
    text-align: center;
  }
  .logo-wrapper{
    /* display: inline-table; */
    width: calc(33% - 30px);
    @media only screen and (max-width : 690px) {
      width: initial;
    }
    /* width: calc((100vw - 90px - 90px) / 3 - 30px); */
    padding: 15px;
    vertical-align: middle;
    text-align: center;
  }
  img {
    background: #FFF
    /* &:nth-child(3n + 1) {
      clear: left;
    } */
  }
`;

export {
  PagePortfolioWrapper,
  FilterWrapper,
  Logos,
};