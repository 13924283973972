import React, { useEffect } from 'react';
import NavHeader from '../NavHeader';
import PageHeader from '../PageHeader';
import Footer from '../Footer';
import { Container } from '../GlobalStyles';
import { PageBiographyWrapper } from './PageBiographyStyles';
import img1 from '../../images/biography1.png';
import img2 from '../../images/biography2.png';
import img3 from '../../images/biography3.png';
import img4 from '../../images/biography4.png';

function PageBiography({ history }) {
  useEffect(() => {
    document.title = 'Biography | Weese Sports Portfolio';
  });

  return (
    <div>
      <NavHeader history={history} />
      <PageHeader path={[{title: 'Biography', url: '/biography'}]} />
      <Container>
        <PageBiographyWrapper>
          <div className="text">
            <h3>The Hockey News Summer Internship (June 2020-September 2020)</h3>
            <ul>
              <li>Edited articles written by the senior writers.</li>
              <li>Wrote feature stories for the digital site.</li>
            </ul>
            <h3>NESN Externship (July 2020-August 2020)</h3>
            <ul>
              <li>Eight-week virtual internship with New England Sports Network (NESN).</li>
              <li>Gained knowledge about various aspects of sports media and business.</li>
              <li>Made connections with individuals working at NESN.</li>
              <li>Developed skills useful for securing job opportunities in sports media (e.g. interviewing, cover letter, resume)</li>
            </ul>
            <h3>Freelance Writer/Reporter (April 2018-present)</h3>
            <ul>
              <li>Sportsnet</li>
              <li>GOLF Magazine/GOLF.com</li>
              <li>PGA Tour</li>
              <li>Canadian Baseball Network</li>
              <li>Raptors Republic</li>
              <li>Last Word on Sports</li>
            </ul>
            <h3>MyHockey TV (September 2019-March 2020)</h3>
            <ul>
              <li>Play-by-Play Broadcaster for the Ontario Junior Hockey League (OJHL) Stouffville Spirit franchise. </li>
            </ul>
            <h3>Rogers TV (July 2014-present)</h3>
            <ul>
              <li>Play-by-Play Broadcaster for local sports programming including the OHL.</li>
            </ul>
          </div>
          <div className="images">
            <img src={img1} alt="Lukas Weese" />
            <img src={img2} alt="Lukas Weese" />
            <img src={img3} alt="Lukas Weese" />
            <img src={img4} alt="Lukas Weese" />
          </div>
        </PageBiographyWrapper>
      </Container>
      <Footer />
    </div>
  );
}

export default PageBiography;