import styled from 'styled-components';
import SportsBalls from '../../images/sports-balls.jpg';
import { isIOS } from '../../utils';

const HomeWrapper = styled.div`
  /* background-color: var(--off-white); */
  .hero-wrapper {
    height: 100vh;
    height: ${props => props.height - (isIOS ? 0 : 128)}px;
    width: 100vw;
    overflow: hidden;
    background: url(${SportsBalls}) top center / cover;
  }
  /* .video-wrapper {
    height: 100vh;
    height: ${props => props.height};
    width: 100vw;
    overflow: hidden;
    background-color: var(--off-black);
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    } */
    .feature-logo {
      position: absolute;
      top: 50vh;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      opacity: 1;
      max-height: 70vh;
      max-width: 80vw;
      color: var(--off-white);
      text-align: center;

      box-shadow: 0 0 90px 40px #000;
      background: rgba(0,0,0,.79);
      border-radius: 20%;
      img {
        max-width: 100%;
        max-height: calc(70vh - 1.8em);
      }
      span {
        display: block;
        /* line-height: 1.2em;
        font-size: 1.8em;
        letter-spacing: .06em;
        padding: .1em .4em .2em; */
        text-align: center;
      }
      .first-name, .last-name {
        font-family: 'Poiret One', cursive;
        font-size: 10em;
        @media only screen and (max-width : 1000px) {
          font-size: 8em;
        }
        @media only screen and (max-width : 690px) {
          font-size: 5em;
          letter-spacing: 0;
        }
      }
      .first-name {
        color: var(--link-accent);
        font-weight: 800;
      }
      .last-name {
        /* -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: var(--off-white);
        color: transparent; */
      }

    }
    .down-button {
      animation: 3.4s ease-in-out infinite fadebottom;
      position: absolute;
      top: calc(${props => props.height}px - 4.5em);
      left: calc(50% - 2.25em);
      color: #FFF;
      height: 4.5em;
      width: 4.5em;
      z-index: 5;
      cursor: pointer;
      border: 0;
      background: none;
      svg {
        stroke: #FFF;
        stroke-width: 4;
        stroke-linecap: round;
        stroke-linejoin: round;
        fill: none;
        height: 3em;
        width: 2em;
      }
    }
  }
  .fade {
    transition: opacity 3s;
    opacity: 0;
    &.visible{
      opacity: 1;
    }
  }
`;

const VideoRow = styled.div`
  margin: 0 auto 40px auto;
`;

const About = styled.div`
  margin-bottom: 40px;
  img {
    max-width: 40%;
    @media only screen and (max-width : 800px) {
      max-width: 100%;
    }
  }
  .about-text {
    display: inline-block;
    margin-left: 70px;
    vertical-align: top;
    @media only screen and (max-width : 1000px) {
      margin-left: 20px;
    }
    span {
      font-size: 2.1em;
      font-weight: 100;
      display: block;
      @media only screen and (max-width : 1000px) {
        font-size: 1.7em;
      }
    }
    .first-name, .last-name {
        font-family: 'Poiret One', cursive;
        font-size: 5em;
        display: inline-block;
        color: var(--link-accent);
        font-weight: 800;
        @media only screen and (max-width : 1000px) {
          font-size: 4em;
        }
        @media only screen and (max-width : 690px) {
          font-size: 3em;
          letter-spacing: 0;
        }
      }
      .tag-line{
        font-size: 3em;
        /* text-align: center; */
        color: var(--link-accent);
        font-weight: 800;
        margin-bottom: 50px;
        @media only screen and (max-width : 690px) {
          font-size: 2em;
        }
      }
  }
`;

const CarouselHeader = styled.div`
  text-align: center;
  padding: 2.5em 0;
  h2 {
    font-family: 'OpenSansLight';
    letter-spacing: .05em;
  }
  button, a {
    color: var(--dark-grey);
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0 .4em;
    margin: 0 1.2em;
    :hover {
      color: var(--link-accent);
    }
    :nth-last-child(2) svg {
      height: 2.1em;
      width: 2.1em;
    }
  }
  svg {
    stroke: currentColor;
    stroke-width: 1.5;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    height: 3em;
    width: 1.8em;
  }
`;

const CarouselBody = styled.div`
  display: flex;
  flex-direction: row;
  background: var(--off-black);
  overflow-x: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar { height: 0; }
  > div {
    min-width: 33.33333333vw;
  }
  @media only screen and (max-width : 1000px) {
    > div {
      min-width: 50vw;
    }
  }
  @media only screen and (max-width : 690px) {
    > div {
      min-width: 100vw;
    }
  }
`;

const ButtonRow = styled.div`
  background-color: var(--dark-grey);
  padding: 3.5em 0;
  text-align: center;
  color: var(--off-white);
  font-size: 1.2em;
  a {
    background-color: var(--link-accent);
    padding: .9em 3.5em;
    border-radius: .25em;
    color: #FFF;
    font-family: 'OpenSansbold';
    font-size: .65em;
    margin-left: 2em;
    letter-spacing: .16em;
    text-transform: uppercase;
    position: relative;
    span {
      left: 0;
      position: relative;
      transition: all 0.2s ease-out;
    }
    :hover {
      span {
        left: -1.2em;
      }
    }
    svg.fa-arrow-right {
      height: 1.5em;
      width: 1.5em;
      right: 2.5em;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      transition: all 0.2s ease-out;
    }
    :hover svg {
      right: 2em;
      opacity: 1;
    }
  }
  @media only screen and (max-width : 690px) {
    a {
      display: block;
      margin: 2.5em 0 0;
    }
  }
`;

const TestimonialsWrapper = styled.div`
  font-size: 1.4em;
  text-align: center;
  font-family: 'OpenSansLight';
  line-height: 1.75em;
  color: var(--off-black);
  letter-spacing: .02em;
  .slides {
    position: relative;
    transition: height 300ms;
    height: 300px; /* for initial load */
    height: ${props => props.currentTestimonialHeight + 50 + 'px'};
    @media only screen and (max-width : 690px) {
      height: 600px; /* for initial load */
      height: ${props => props.currentTestimonialHeight + 100 + 'px'};
    }
  }
  blockquote{
    opacity: 0;
    position: absolute;
    left: -2em;
    transition: all 500ms;
    width: calc(100% - 80px);
    &.active {
      left: 0;
      opacity: 1;
      transition: opacity 0;
    }
  }
  span {
    font-size: .7em;
  }

  ul {
    list-style: none;
    padding: 1em 0 2em;
    margin: 0;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
    background: var(--off-white);
    li {
      border: solid 1px var(--off-black);
      border-radius: 50%;
      height: .5em;
      width: .5em;
      margin: 0 .2em;
      cursor: pointer;
      &.active, :hover, :active{
        background-color: var(--off-black);
      }
    }
  }
`;

export {
  HomeWrapper,
  VideoRow,
  About,
  CarouselHeader,
  CarouselBody,
  ButtonRow,
  TestimonialsWrapper,
}