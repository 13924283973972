import React from 'react';
import { IFrame } from '../components/GlobalStyles';

const social = {
  facebook: 'https://facebook.com/weesesports/',
  twitter: 'https://twitter.com/weesesports',
  instagram: 'https://www.instagram.com/lukasweese/',
  linkedin: 'https://www.linkedin.com/in/lukas-weese-52760b98',
};

const portfolioItems = [
  {
    title: 'The Hockey News',
    category:'Writing',
    img: 'the-hockey-news.png',
    href: 'https://www.si.com/hockey/news/diabetes-hasnt-stopped-ohls-mark-woolley-from-making-an-impact'
  },
  {
    title: 'Sportsnet',
    category: 'Writing',
    img:'sportsnet.png',
    href: 'https://www.sportsnet.ca/author/lukas-weese/'
  },
  {
    title: 'GOLF',
    category: 'Writing',
    img:'golf.jpg',
    href: 'https://golf.com/news/michael-gligic-battled-decade-pga-tour/'
  },
  {
    title: 'Baseline',
    category: 'Writing',
    img: "baseline.png",
    href: "http://baseline.tennis.com/search/authors/Lukas%20Weese"
  },
  {
    title: 'Canadian Baseball Network',
    category: 'Writing',
    img: "canadian-baseball-network.png",
    href: "https://www.canadianbaseballnetwork.com/canadian-baseball-network-articles/curtis-taylor-embracing-opportunity-with-blue-jays?format=amp"
  },
  {
    title: 'Tigernet',
    category: 'Writing',
    img: "tigernet.png",
    href: "https://www.tigernet.com/story/From-Canada-to-Clemson-Ajou-Ajous-Journey-to-Becoming-a-Tiger-18768"
  },
  {
    title: 'LWOS Baseball',
    category: 'Writing',
    img: "lwos-baseball.png",
    href: "https://lastwordonbaseball.com/author/lukasweese/"
  },
  {
    title: 'LWOS Football',
    category: 'Writing',
    img: "lwos-football.png",
    href: "https://lastwordoncollegefootball.com/author/lukasweese/"
  },
  {
    title: 'LWOS Hockey',
    category: 'Writing',
    img: "lwos-hockey.png",
    href: "https://lastwordonhockey.com/author/lukasweese/"
  },
  {
    title: 'LWOS Pro Football',
    category: 'Writing',
    img: "lwos-pro-football.png",
    href: "https://lastwordonprofootball.com/author/lukasweese/"
  },
  {
    title: 'LWOS Tennis',
    category: 'Writing',
    img: "lwos-tennis.png",
    href: "https://lastwordontennis.com/author/lukasweese/"
  },
  {
    title: 'LWOS',
    category: 'Writing',
    img: "lwos.png",
    href: "https://lastwordonsports.com/author/lukasweese/"
  },
  {
    title: 'Raptors',
    category: 'Writing',
    img: "raptors.jpg",
    href: "https://www.raptorsrepublic.com/author/lukas/"
  },
  {
    title: 'The Hockey Writers',
    category: 'Writing',
    img: "the-hockey-writers.jpg",
    href: "https://thehockeywriters.com/author/lweese/"
  },
  {
    title: 'Mackenzie Tour',
    category: 'Writing',
    img: "mackenzie-tour.png",
    href: "https://www.pgatour.com/canada/en_us/news/2020/04/20/james-allenby-love-of-golf-propels-through-tough-times.html"
  },
  {
    category: 'Reporting',
    content: (<div><IFrame width="1206" height="678" src="https://www.youtube.com/embed/videoseries?list=PLcmWA2qKKVr1FDMiWNhJpCXqls6aEDEsg" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /></div>)
  },
  {
    category: 'Reporting',
    content: (<div><IFrame width="1206" height="678" src="https://www.youtube.com/embed/videoseries?list=PLcmWA2qKKVr0-AiFBdkfA1uooN-wzzqNq" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /></div>)
  },
  {
    category: 'Reporting',
    content: (<div><IFrame width="1206" height="678" src="https://www.youtube.com/embed/videoseries?list=PLcmWA2qKKVr2b3PHhxoi5xtgHBOSl_lYj" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /></div>)
  },
  {
    category: 'Reporting',
    content: (<div><IFrame width="1206" height="678" src="https://www.youtube.com/embed/videoseries?list=PLcmWA2qKKVr3ZVa5JGNNqDBYPPSlSKBx_" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /></div>)
  },
  {
    category: 'Reporting',
    content: (<div><IFrame width="1206" height="678" src="https://www.youtube.com/embed/uZxwbiHdzto" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /></div>)
  },
  {
    category: 'Reporting',
    content: (<div><IFrame width="1206" height="678" src="https://www.youtube.com/embed/R0C-0e1iiSQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /></div>)
  },
  {
    category: 'Reporting',
    content: (<div><IFrame width="1206" height="678" src="https://www.youtube.com/embed/q6g4f6J4uAw" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /></div>)
  },
  {
    category: 'Multimedia & Broadcasting',
    content: (<div><IFrame width="1206" height="678" src="https://www.youtube.com/embed/WQYgy8ieG4A" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /></div>)
  },
  {
    category: 'Multimedia & Broadcasting',
    content: (<div><IFrame width="1206" height="678" src="https://www.youtube.com/embed/vHCPc_hhrJU" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /></div>)
  },
  {
    category: 'Multimedia & Broadcasting',
    content: (<div><IFrame width="1206" height="678" src="https://www.youtube.com/embed/F7dVlA70w1A" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /></div>)
  },
  {
    category: 'Multimedia & Broadcasting',
    content: (<div><IFrame width="1206" height="678" src="https://www.youtube.com/embed/i8vOMi_KaDg" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /></div>)
  },
  {
    category: 'Multimedia & Broadcasting',
    content: (<div><IFrame width="1206" height="678" src="https://www.youtube.com/embed/x-qrNVNUmY0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /></div>)
  },
  {
    category: 'Multimedia & Broadcasting',
    content: (<div><IFrame width="1206" height="678" src="https://www.youtube.com/embed/srQYdFRlriw" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /></div>)
  },
  {
    title: 'YouTube',
    category: 'Podcasts',
    img: 'youtube-logo.png',
    href: 'https://www.youtube.com/playlist?list=PLcmWA2qKKVr00O0A10sJii9vn71mZ9uBq',
  },
  {
    title: 'Apple Podcasts',
    category: 'Podcasts',
    img: 'apple-podcast.png',
    href: 'https://podcasts.apple.com/ca/podcast/weesesports-quarantine-chronicles/id1513685677',
  },
  {
    title: 'Spreaker',
    category: 'Podcasts',
    img: 'spreaker.png',
    href: 'https://www.spreaker.com/show/weesesports-quarantine-chronicles',
  },
  {
    title: 'Spotify',
    category: 'Podcasts',
    img: 'spotify.png',
    href: 'https://open.spotify.com/show/1rrOvV13O9IdyooCYVJAzs?si=ogjLE0x3RMCJxk0asetIkg',
  },
  {
    title: 'Sportsnet 590 The FAN',
    category: 'Radio',
    img: 'sportsnet-radio.png',
    href: 'https://www.sportsnet.ca/590/sportsnet-tonight/dec-29-rangers-snap-leafs-win-streak-and-the-raptors-avenge-christmas-day-loss/',
  },
];

export {
  social,
  portfolioItems,
};