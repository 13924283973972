import React from 'react';
import { Container } from '../GlobalStyles';
import { FilterWrapper } from './PagePortfolioStyles';

function Filter({onCategoryChange, currentCategory, categories}) {
  const categorySelection = category => (event) => {
    event.preventDefault();
    onCategoryChange(category);
  }

  return (
    <FilterWrapper>
      <Container>
        <div className="inner-wrapper">
          <div className="filter-title"><span>Filter:</span></div>
          {categories.map(category => (
          <div className="category" key={category}>
            <button onClick={categorySelection(category)} className={currentCategory===category?'active':''}>
              <span>{category}</span>
            </button>
          </div>
          ))}
        </div>
      </Container>
    </FilterWrapper>
  );
}

export default Filter;