import React, { useEffect } from 'react';
import NavHeader from '../NavHeader';
import PageHeader from '../PageHeader';
import Footer from '../Footer';
import { Container } from '../GlobalStyles';
import { PageBiographyWrapper } from './PageServicesStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPenNib,
} from '@fortawesome/free-solid-svg-icons';
import social from '../../images/logo-social-media.jpg';
import fcpr from '../../images/logo-Final_Cut_Pro_Premiere.png';
import ps from '../../images/logo-Adobe_Photoshop.png';
import au from '../../images/logo-Adobe_Audition.png';
import wp from '../../images/logo-wordpress.png';

function PageServices({ history }) {
  useEffect(() => {
    document.title = 'Services | Weese Sports Portfolio';
  });

  return (
    <div>
      <NavHeader history={history} />
      <PageHeader path={[{title: 'Services', url: '/services'}]} />
      <Container>
        <PageBiographyWrapper>
          <div className="service">
            <div className="icon">
              <FontAwesomeIcon icon={faPenNib} />
            </div>
            <div className="service-text">
              <h3>Writing</h3>
              <ul>
                <li>Digital</li>
                <li>Print</li>
                <li>Broadcast</li>
                <li>Social Media</li>
              </ul>
            </div>
          </div>
          <div className="service">
            <div className="icon" style={{marginTop: '25px'}}>
              <img src={social} alt="Facebook Twitter Instagram Snapchat" />
            </div>
            <div className="service-text">
              <h3>Social Media</h3>
              <ul>
                <li>Facebook</li>
                <li>Twitter</li>
                <li>Instagram</li>
                <li>Snapchat</li>
              </ul>
            </div>
          </div>
          <div className="service">
            <div className="icon">
              <img src={fcpr} alt="Final Cut Pro & Premiere Pro" />
            </div>
            <div className="service-text">
              <h3>Video Production</h3>
              <ul>
                <li>Final Cut Pro X</li>
                <li>Adobe Premiere Pro</li>
              </ul>
            </div>
          </div>
          <div className="service">
            <div className="icon">
              <img src={ps} alt="Photoshop" />
            </div>
            <div className="service-text">
              <h3>Imaging</h3>
              <ul>
                <li>Adobe Photoshop</li>
              </ul>
            </div>
          </div>
          <div className="service">
            <div className="icon">
              <img src={au} alt="Adobe Audition" />
            </div>
            <div className="service-text">
              <h3>Audio Editing</h3>
              <ul>
                <li>Adobe Audition</li>
              </ul>
            </div>
          </div>
          <div className="service">
            <div className="icon">
              <img src={wp} alt="WordPress" />
            </div>
            <div className="service-text">
              <h3>Website</h3>
              <ul>
                <li>Wordpress</li>
                <li>SEO and UGC Strategies</li>
              </ul>
            </div>
          </div>
        </PageBiographyWrapper>
      </Container>
      <Footer />
    </div>
  );
}

export default PageServices;