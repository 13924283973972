import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // faSearch,
  faBars,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { Container } from '../GlobalStyles';
import {
  HeaderWrapper,
  Header,
  // HeaderLogo,
  NavContainer,
  LinksContainer,
  NavSearchInputContainer,
  MobileNavContainer,
  ToggleMobileNavButton,
} from './HeaderStyles';
import {
  social,
} from  '../../config';
// import bannerLogo from '../../images/Dave-Petrasovic-Banner-Logo.png';

const DESKTOP_WIDTH_THRESHOLD = 1000;
const MINIMUM_SEARCH_LENGTH = 3;
class NavHeader extends Component {
  state = {
    searchOpen: 'closed',
    mobileMenuOpen: false,
    searchQuery: '',
    scrollIsAtTop: window.innerWidth >= DESKTOP_WIDTH_THRESHOLD,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollAction);
    window.addEventListener('resize', this.scrollAction);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollAction);
    window.removeEventListener("resize", this.scrollAction);
  }
  scrollAction = () => {
    const positionFromTop = window.pageYOffset || document.documentElement.scrollTop;
    this.setState({
      scrollIsAtTop: positionFromTop === 0 && window.innerWidth >= DESKTOP_WIDTH_THRESHOLD
    });
  }

  toggleSearch = () => {
    const { searchOpen } = this.state;
    if (searchOpen === 'closed') {
      this.setState({ searchOpen: 'opening'}, () => {
        setTimeout(() => {
          this.setState({ searchOpen: 'open' });
          this.mainSearch && this.mainSearch.focus();
        });
      });
    } else {
      this.setState({ searchOpen: 'opening'}, () => {
        setTimeout(() => this.setState({ searchOpen: 'closed' }), 300);
      });
    }
  }
  toggleSearchFromKeyboard = (event) => {
    if (event.key === ' ' || event.key === 'Enter') {
      this.toggleSearch();
    }
  }

  toggleMobileMenu = () => {
    const { mobileMenuOpen } = this.state;
    this.setState({ mobileMenuOpen: !mobileMenuOpen });
  }
  closeMobileMenu = () => this.setState({ mobileMenuOpen: false });

  updateSearchQuery = (event) => {
    this.setState({ searchQuery: event.target.value });
  }

  runSearch = (event) => {
    const { searchQuery } = this.state;
    if (event.key === 'Enter' && searchQuery.length >= MINIMUM_SEARCH_LENGTH) {
      this.closeMobileMenu();
      this.props.history.push(`/search/${searchQuery}`);
    }
  }

  getSocialLink = (icon, link, ariaLabel) => (
    <li>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={ariaLabel}
      >
        <span>
          <FontAwesomeIcon icon={icon} />
        </span>
      </a>
    </li>
  );

  render() {
    const {
      searchOpen,
      mobileMenuOpen,
      searchQuery,
    } = this.state;
    return (
      <HeaderWrapper>
        <Header open={mobileMenuOpen} searchOpen={searchOpen !== 'closed'}>
          <Container>
            <NavSearchInputContainer open={searchOpen}>
              <input
                type="text"
                placeholder="Enter some keywords..."
                ref={(ref) => { this.mainSearch = ref; }}
                onChange={this.updateSearchQuery}
                value={searchQuery}
                aria-label="Search website"
                onKeyDown={this.runSearch}
                minLength={MINIMUM_SEARCH_LENGTH}
              />
              <button onClick={this.toggleSearch} className="nav-search-clear" aria-label="Close Search">&times;</button>
            </NavSearchInputContainer>
            <NavContainer>
              <div className="logo-container">
                <a href="/" id="logo">
                  <h1>
                    Lukas Weese
                    <span className="tagline">Journalist</span>
                  </h1>
                  {/* <HeaderLogo src={bannerLogo} alt="Dave Petrasovic" /> */}
                </a>
              </div>
              <LinksContainer>
                <nav>
                  <ul>
                    <li>
                      <NavLink to="/" exact activeClassName="active">
                        <span>Home</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/biography" activeClassName="active">
                        <span>Biography</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/portfolio" activeClassName="active">
                        <span>Portfolio</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/services" activeClassName="active">
                        <span>Services</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/contact" activeClassName="active">
                        <span>Contact</span>
                      </NavLink>
                    </li>
                    <li className="spacer"/>
                    {this.getSocialLink(faFacebook, social.facebook, 'Facebook')}
                    {this.getSocialLink(faTwitter, social.twitter, 'Twitter')}
                    {this.getSocialLink(faInstagram, social.instagram, 'Instagram')}
                    {this.getSocialLink(faLinkedin, social.linkedin, 'LinkedIn')}
                    {/* <li className="spacer"/>
                    <li>
                      <div
                        className="search-button-wrapper"
                        onClick={this.toggleSearch}
                        role="button"
                        tabIndex="0"
                        aria-label="Open search field"
                        onKeyDown={this.toggleSearchFromKeyboard}
                      >
                        <span className="search-link-span">
                          <FontAwesomeIcon icon={faSearch} />
                        </span>
                      </div>
                    </li> */}
                  </ul>
                </nav>
              </LinksContainer>
              <ToggleMobileNavButton
                onClick={this.toggleMobileMenu}
                aria-controls="mobileNavMenu"
                aria-expanded={mobileMenuOpen}
                aria-label="Toggle navigation"
              >
                <FontAwesomeIcon icon={faBars} />
              </ToggleMobileNavButton>
            </NavContainer>
          </Container>
          <MobileNavContainer open={mobileMenuOpen} id="mobileNavMenu">
            <Container>
              <ul>
                <li>
                  <NavLink to="/" exact onClick={this.closeMobileMenu}>Home</NavLink>
                </li>
                <li>
                  <NavLink to="/biography" onClick={this.closeMobileMenu}>Biography</NavLink>
                </li>
                <li>
                  <NavLink to="/portfolio" onClick={this.closeMobileMenu}>Portfolio</NavLink>
                </li>
                <li>
                  <NavLink to="/services" onClick={this.closeMobileMenu}>Services</NavLink>
                </li>
                <li>
                  <NavLink to="/contact" onClick={this.closeMobileMenu}>Contact</NavLink>
                </li>
                {/* <input
                  type="text"
                  placeholder="Search..."
                  onChange={this.updateSearchQuery}
                  value={searchQuery}
                  aria-label="Search website"
                  onKeyDown={this.runSearch}
                  minLength={MINIMUM_SEARCH_LENGTH}
                /> */}
              </ul>
            </Container>
          </MobileNavContainer>
        </Header>
      </HeaderWrapper>
    );
  }
}

export default NavHeader;