import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container } from '../GlobalStyles';
import { PageHeaderWrapper } from './PageHeaderStyles';

function PageHeader({ path }) {

  return (
    <PageHeaderWrapper>
      <Container>
        <h2>{path.slice(-1)[0].title}</h2>
        <ul>
          <li>
            {/* <a href="/">Home</a> */}
            <NavLink to="/">Home</NavLink>
          </li>
          {path.map(item => (
            <div key={item.url}>
              <li className="separator">&gt;</li>
              <li>
                <NavLink to={item.url}>
                  {item.title}
                </NavLink>
              </li>
            </div>
          ))}
        </ul>
      </Container>
    </PageHeaderWrapper>
  );
}

export default PageHeader;