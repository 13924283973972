import styled from 'styled-components';
import SportsBalls from '../../images/sports-balls.jpg';

const PageHeaderWrapper = styled.div`
  background: url(${SportsBalls}) top center / cover;
  height: 450px;
  color: var(--off-white);
  > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
    max-width: 100%;
  }
  h2 {
    color: var(--link-accent);
    font-size: 5em;
    margin: 0;
    font-family: 'Poiret One', cursive;
  }
  ul {
    list-style: none;
    padding: 0;
    div {
      display: inline;
    }
    li {
      display: inline;
      &.separator {
        margin: 0 10px;
      }
      a {
        color: var(--off-white);
      }
    }
  }
`;

export { PageHeaderWrapper };