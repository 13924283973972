import styled from 'styled-components';

const PageContactWrapper = styled.div`
  margin-top: 80px;
  text-align: center;
  .portrait-container {
    display: inline-block;
    margin: 0 80px 80px 0;
    @media only screen and (max-width : 690px) {
      margin: 0 0 50px 0;
    }
    img {
      box-shadow: -20px 20px 0 var(--light-grey);
      margin: 0 0 20px 20px;
      max-width: calc(100vw - 60px);
    }
  }
  .links-container {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 80px;
  }
  ul {
    text-align: left;
    list-style: none;
    padding: 0;
    margin-top: 0;
    font-size: 1.5em;
    line-height: 2.5em;
  }
`;

export { PageContactWrapper };