import styled from 'styled-components';

const PageBiographyWrapper = styled.div`
  margin-top: 70px;
  .text {
    width: calc(70% - 50px);
    display: inline-block;
    vertical-align: top;
    margin-right: 50px;
    line-height: 2.2em;
    h3 {
      line-height: 2em;
    }
    ul {
      margin-bottom: 100px;
    }
  }
  .images {
    width: 30%;
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 15px;
    img {
      max-width: 100%;
      margin-top: 5px;
    }
  }

  @media only screen and (max-width : 690px) {
    .text, .images{
      width: 100%;
      margin-right: 0;
    }
  }

  .accomplishments {
    background: url('https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/ScotiaBank_Place_Inside_empty_2006.jpg/1200px-ScotiaBank_Place_Inside_empty_2006.jpg') center center / cover;
    margin: 90px 0 80px;
    ul {
      list-style: none;
      padding: 0;
      background: rgba(255, 255, 255, 0.85);
      padding: 0 10px;
      li {
        width: calc(33% - 10px);
        min-width: 300px;
        display: inline-block;
        padding: 0 5px;
      }
    }
  }
  h3 {
    color: var(--link-accent);
    font-size: 1.8em;
    margin: .5em 0;
  }
`;

export { PageBiographyWrapper };