import styled from 'styled-components';

const PageBiographyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .service {
    min-width: 400px;
    display: flex;
    flex-direction: row;
    .icon {
      font-size: 100px;
      margin: 20px 30px 0 0;
      img {
        width: 100px;
      }
    }
  }

    @media only screen and (max-width : 690px) {
    .service {
      min-width: unset;
    }
    .service-text {
      overflow-wrap: break-word;
      max-width: 100%;
    }
  }
`;

export { PageBiographyWrapper };