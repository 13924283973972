import styled from 'styled-components';
import { Container } from '../GlobalStyles';

const FooterWrapper = styled.footer`
  background: var(--dark-grey);
  color: var(--light-grey);
  height: 58px;
  font-size: .75em;
  position: absolute;
  bottom: 0;
  width: 100%;
`;
const FooterContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`;
const Copyright = styled.div`
  display: table;
  height: 100%;
  p {
    display: table-cell;
    vertical-align: middle;
  }
`;
const SocialList = styled.ul`
  list-style: none;
  margin: 0;
  display: table;
  height: 100%;
  > li {
    display: table-cell;
    vertical-align: middle;
    > a {
      color: var(--medium-grey);
      margin-left: .8em;
      transition: color 500ms;
      font-size: 2em;
      &:hover{
        color: var(--link-accent);
      }
    }
  }
`;

export {
  FooterWrapper,
  FooterContainer,
  Copyright,
  SocialList,
};