import React, { useEffect } from 'react';
import NavHeader from '../NavHeader';
import PageHeader from '../PageHeader';
import Footer from '../Footer';
import Map from './Map';
import { Container } from '../GlobalStyles';
import { PageContactWrapper } from './PageContactStyles';
import { social } from '../../config';
import Lukas from '../../images/lukas-weese.jpg';

function PageContact({ history }) {
  useEffect(() => {
    document.title = 'Contact | Weese Sports Portfolio';
  });

  return (
    <div>
      <NavHeader history={history} />
      <PageHeader path={[{title: 'Contact', url: '/contact'}]} />
      <Map />
      <Container>
        <PageContactWrapper>
          <div>
            <div className="portrait-container">
              <img src={Lukas} alt="Lukas Weese" />
            </div>
            <div className="links-container">
              <ul>
                <li>Facebook <a href={social.facebook} target="_blank" rel="noopener noreferrer">Lukas Weese</a></li>
                <li>Twitter <a href={social.twitter} target="_blank" rel="noopener noreferrer">@Weesesports</a></li>
                <li>Instagram <a href={social.instagram} target="_blank" rel="noopener noreferrer">lukasweese</a></li>
                <li>LinkedIn <a href={social.linkedin} target="_blank" rel="noopener noreferrer">Lukas Weese</a></li>
                <li>Email <a href="mailto:weeselukas@gmail.com">weeselukas@gmail.com</a></li>
              </ul>
            </div>
          </div>
        </PageContactWrapper>
      </Container>
      <Footer />
    </div>
  );
}

export default PageContact;