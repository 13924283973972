import React, { Component } from 'react';
import ReactMapGL from 'react-map-gl';

class Map extends Component {
  state = {
    viewport: {
      width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      height: Math.min(400, Math.max(document.documentElement.clientHeight, window.innerHeight || 0) * .4), // max-height: 40vh
      latitude: 43.674648,
      longitude: -79.390336,
      zoom: 9,
    }
  };

  render() {
    return (
      <ReactMapGL
        {...this.state.viewport}
        onViewportChange={(viewport) => this.setState({viewport})}
        scrollZoom={false}
      />
    );
  }
}

export default Map;