import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import {
  FooterWrapper,
  FooterContainer,
  Copyright,
  SocialList,
} from './FooterStyles';
import {
  social,
} from  '../../config';

function Footer() {
  const getSocialLink = (icon, link, ariaLabel) => (
    <li>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={ariaLabel}
      >
        <FontAwesomeIcon icon={icon} />
      </a>
    </li>
  );
  const year = new Date().getFullYear();
  return (
    <FooterWrapper>
      <FooterContainer>
        <Copyright>
          <p>&copy; {year} Lukas Weese</p>
        </Copyright>
        <SocialList>
          {getSocialLink(faFacebook, social.facebook, 'Facebook')}
          {getSocialLink(faTwitter, social.twitter, 'Twitter')}
          {getSocialLink(faInstagram, social.instagram, 'Instagram')}
          {getSocialLink(faLinkedin, social.linkedin, 'LinkedIn')}
        </SocialList>
      </FooterContainer>
    </FooterWrapper>
  );
}

export default Footer;