import React, { Component } from 'react';
// import { NavLink } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { isIOS } from '../../utils';
import NavHeader from '../NavHeader';
import Footer from '../Footer';
// import Carousel from './Carousel';
// import Testimonials from './Testimonials';
// import { Container } from '../GlobalStyles';
import {
  HomeWrapper,
  VideoRow,
  About,
  // ButtonRow,
} from './HomeStyles';
// import largeLogo from '../../images/Dave-Petrasovic-Logo.png';
// import { chevronDown } from '../SVGs';
import { Container, IFrame } from '../GlobalStyles';
import Lukas from '../../images/lukas-weese.png';

class Home extends Component {
  state = {
    videoInView: false,
    // videoInView2: false,
    photoInView: false,
  }

  onScroll = event => {
    const viewUpTo = (window.scrollTop || document.documentElement.scrollTop) + window.innerHeight
    if (viewUpTo > (this.videoElement && this.videoElement.offsetTop)) {
      this.setState({ videoInView: true })
    }
    // if (viewUpTo > (this.videoElement2 && this.videoElement2.offsetTop)) {
    //   this.setState({ videoInView2: true })
    // }
    if (viewUpTo > (this.photoElement && this.photoElement.offsetTop)) {
      this.setState({ photoInView: true })
    }

  }

  componentDidMount() {
    document.title = 'Lukas Weese \'s Portfolio - Weese Sports';
    window.addEventListener('scroll', this.onScroll)
    setTimeout(() => this.onScroll(), 400)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  scrollDown = () => {
    const { clientHeight, clientWidth } = this.heroWrapper;
    const scrollBy = clientHeight + (clientWidth < 1000 ? 58 : -128);
    window.scroll({
      top: scrollBy,
      left: 0,
      behavior: 'smooth'
    });
  }

  getWindowHeight = () => {
    return isIOS() ? window.innerHeight - 40 : window.innerHeight;
  }

  render() {
    const { history } = this.props;
    const { videoInView, /* videoInView2, */ photoInView } = this.state;
    return (
      <HomeWrapper height={this.getWindowHeight()}>
        <NavHeader transparent history={history} />
        {/* <div className="hero-wrapper" ref={ref => this.heroWrapper = ref} style={{display: 'none'}}>
          <div className="feature-logo">
            <span className="first-name">Lukas</span>
            <span className="last-name">Weese</span>
            <span className="tag-line">Journalist</span>
          </div>
          <button
            className="down-button"
            aria-label="Scroll down to explore"
            onClick={this.scrollDown}
          >
            {chevronDown}
          </button>
        </div> */}
        {/* <Carousel /> */}

        <VideoRow>
          <Container ref={el => this.videoElement = el} className={videoInView?'fade visible':'fade'}>
            <IFrame  width="1206" height="678" src="https://www.youtube.com/embed/ZnK1Yq_Ep7U" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </Container>
        </VideoRow>
        {/* <VideoRow>
          <Container ref={el => this.videoElement2 = el} className={videoInView2?'fade visible':'fade'}>
            <IFrame  width="1206" height="678" src="https://www.youtube.com/embed/hPNDUvkCRN8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </Container>
        </VideoRow> */}
        <About>
          <Container>
            <img src={Lukas} alt="Lukas Weese" ref={el => this.photoElement = el} className={photoInView?'fade visible':'fade'} />
            <div className="about-text">
              <span className="base-text text1">Hi there! My name is</span>
              <span className="first-name">Lukas</span>
              <span className="last-name">Weese</span>
              <span className="base-text text2">and I am a multimedia</span>
              <span className="tag-line">Journalist.</span>
              <span className="base-text text3">Welcome to my website.</span>
            </div>
          </Container>
        </About>
        {/* <ButtonRow>
          <Container>
            <p>
              But wait! There’s more!
              <NavLink to="/projects" aria-label="view all projects">
                <span>View Portfolio</span>
                <FontAwesomeIcon icon={faArrowRight} />
              </NavLink>
            </p>
          </Container>
        </ButtonRow> */}
        {/* <Testimonials /> */}
        <Footer />
      </HomeWrapper>
    );
  }
}
export default Home;